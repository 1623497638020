import React from "react"
import Layout from "../layouts/layout"
import aboutPic from "../images/about.jpg"

export default () => (
  <Layout>
    <div className="content">
      <img
        src={aboutPic}
        alt="profile pic"
        style={{
          float: "center",
          marginBottom: 0,
        }}
      />{" "}
      <div className="post-body">
        <p>
          In college I studied Women’s and Gender Studies, which amounted to 50%
          crying over post-structuralism and trying to read Foucault in the
          original French, 50% wearing pleated mini skirts as praxis.{" "}
        </p>
        <p>
          After graduating, I travelled, did my time in the service industry
          (TIL: prosecco is best straight out of the bottle, through a straw),
          and looked nervously toward the moment my parents' insurance would
          turn into a pumpkin.
        </p>
        <p>
          To preempt that fate, I opted for a software development bootcamp and
          have been working in the tech industry ever since.
        </p>
        <p>
          This blog is an exercise in getting back in touch with my hobbies
          after years of frogmarching myself through life. Reading, exploring,
          creating, learning in public, taking control of my presence on the
          internet.
        </p>
        <p>Thanks for joining me.</p>
      </div>
    </div>
  </Layout>
)
